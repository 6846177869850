.steps {

    opacity: 0;

    &__single {

        display: flex;
        justify-content: center;
        align-items: center;

        &--indicator {

            position: relative;
            width: 35px;
            height: 35px;
            border-radius: 500px;
            background-color: $text-light-grey;

            p {

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 1.2rem;
                font-weight: 500;
                color: #fff;
            }

            .active & {

                background-color: $text-dark-grey;
            }

            .finished & {

                background-color: $progress-dark-green;
            }
        }

        &--text {

            color: $text-light-grey;

            .active & {

                color: $text-dark-grey;
            }

            .finished & {

                color: $progress-dark-green;
            }
        }
    }

    &__seperator {

        width: 5%;
        height: 1px;
        border-radius: 5px;
        background-color: $text-light-grey;

        @include media-breakpoint-down(md) {

            width: 40px;
        }
    }
}
