form {
    width: 100%;

    .form-content {

        max-height: 100%;
        opacity: 1;

        .error {
            border-color: $error-color;
        }

        .error-msg {
            color: $error-color;
            margin-top: .5rem;
        }

        .icon {
            position: absolute;
            height: 24px;
            width: 24px;
            top: 2.4rem;
            right: 1.5rem;
        }

        .error-icon {
            display: none;

            &.show {
                display: block;
            }
        }

        .upload-icon {
            top: var(--pos-top, 2.6rem);
            cursor: pointer;
        }

        .info-icon {
            position: relative;
            top: unset;
            right: unset;
        }

        .close-icon {
            height: 16px;
            width: 16px;
        }

        .eye-icon {
            top: 2.45rem;
            cursor: pointer;
        }

        .edit-icon,
        .trash-icon {

            position: relative;
            top: unset;
            right: unset;
        }

        label {
            display: block;
        }

        input {

            &::placeholder {

                color: $placeholder-grey;
            }
        }

        input[type='file'] {
            color: transparent;
            background: $drop-blue;
            border: 1.5px dashed $btn-blue;
            height: fit-content;

            ~ .file-upload-label {
                position: absolute;
                top: var(--pos-top, 2.45rem);
                left: 1.5rem;
                color: $btn-blue;
                font-weight: 700;
                cursor: pointer;
            }

            &::file-selector-button {
                display: none;
            }

            ~ .open-dialog {
                display: none;
            }

            ~ a {
                display: none;
                position: absolute;
                top: 2.5rem;
                left: 1.5rem;
                font-weight: 700;
                text-decoration: none;
            }

            &.has-file {
                color: transparent;

                ~ .open-dialog,
                ~ a {
                    display: block;
                }

                ~ .upload-icon {
                    display: none;
                }

                ~ .accept-label {
                    display: none;
                }
            }
        }

        input[name='profil_foto'],
        input[name='ausweis_foto'],
        input[name='firmenlogo'] {
            &.has-file {
                display: none;

                ~ .open-dialog {
                    display: block;
                }

                ~ .file-upload-label {
                    position: relative;
                    display: block;
                    width: fit-content;
                    top: 0;
                    left: 0;

                    img {
                        height: auto;
                        width: auto;
                        max-width: 100%;
                    }

                    .edit-overlay {
                        display: block;

                        .edit-icon,
                        .trash-icon {
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
            }
        }

        .delete-formset-entry {
            position: absolute;
            top: var(--pos-top, 1.45rem);
            right: -3rem;
            width: fit-content;
        }

        .btn-in-field {
            position: absolute;
            top: var(--pos-top, 1.45rem);
            right: .8rem;

            .icon {
                position: relative;
                top: unset;
                right: unset;
            }

            ~ .dialog__delete {
                left: 54%;
            }
        }

        .edit-overlay {
            display: none;
        }

        .django-ckeditor-widget {
            display: block !important;
        }
    }

    .form-list {
        height: 75vh;
        overflow-y: scroll;
    }

    input[name='ist_sepa_erteilt'] {

        position: absolute;
        top: 1.55rem;
    }

    #gen-pw {

        position: absolute;
        right: 1rem;
        cursor: pointer;

        @include media-breakpoint-down(sm) {
            font-size: .8rem;
        }
    }

    @include media-breakpoint-up(xxl) {
        width: 75%;
    }
}
