.infobox {
    color: $text-dark-grey;
    border-radius: 2px;

    .training & {

        width: 100%;
        background-color: $drop-green;
    }

    &.grey {
        background: $drop-grey;
    }

    &.green {
        background-color: $drop-green;
    }

    &.orange {
        background-color: $drop-orange;
    }

    &.red {
        background-color: $drop-red;
    }

    &.blue {
        background-color: $drop-blue;
    }
}

.infobox-list > .infobox {
    margin-bottom: 1rem !important;
}
