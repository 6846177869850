.progress {

    height: .6rem;

    :not(:first-child) {
        margin-left: 2%;
    }

    .progress-bar {

        height: .6rem;
        border-radius: 100px;
        background-color: $progress-grey;

        &.dark-green {
            background-color: $progress-dark-green;
        }

        &.light-green {
            background-color: $progress-light-green;
        }

        &.orange {
            background-color: $progress-orange;
        }

        &.red {
            background-color: $progress-red;
        }
    }
}
