.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1600;
    height: 100vh;
    width: 18rem;
    color: $text-bar;
    background-color: $bg-grey;
    transition: all .4s ease-out;

    &.open {
        transform: translateX(0);
    }

    &__header {
        justify-content: center;
    }

    .sidebar-headline {
        text-align: center;
        font-size: 1.2rem;
    }

    .nav-pills {
        width: 100%;

        :hover {
            background-color: $bar-light-blue !important;
        }

        .nav-item {
            display: list-item;
        }

        .nav-link {
            border-radius: 0 !important;
        }

        .active {
            background-color: $bar-dark-blue !important;
        }

        .icon {
            height: 20px;
            width: 20px;
            margin-right: 1rem;
        }

        .childs {
            background-color: $bar-light-blue !important;

            .active {
                background-color: $bar-light-blue !important;
                font-weight: bold;
            }
        }
    }

    .open-overview,
    .open-contacts {

        padding-left: 3.3rem;
        font-size: .9rem;
        text-align: start;
        color: $progress-grey;
        cursor: pointer;
    }

    @include media-breakpoint-down(lg) {
        transform: translateX(-18rem);

        &__header {
            justify-content: space-between;
        }

        .sidebar-headline {
            text-align: left;
            font-size: 1rem;
            margin-right: 1rem !important;
        }
    }

    @include media-breakpoint-down(md) {
        width: 100vw;
        transform: translateX(-100vw);
    }
}
