.login,
.pw-reset {

    &__container {
        margin: 10rem 25% 0;
    }

    &__textbox {
        text-align: center;

        &--title {
            margin-bottom: 3rem;
        }
    }

    &__form {
        &--claim {
            font-weight: 600;
            margin-bottom: 1rem;
        }
    }

    @include media-breakpoint-down(md) {

        &__container {
            margin: 5rem 5% 0;
        }
    }

    @include media-breakpoint-down(sm) {

        &__container {
            margin: 5rem 0 0;
        }
    }
}
