.card {

    .card-title {
        color: $text-dark-grey;
    }

    .card-header {
        background-color: transparent !important;
        border: none !important;
    }

    .label {
        color: $text-light-grey;
    }

    .value {
        color: $text-base;
    }

    a {
        word-wrap: anywhere;
    }

    img {

        max-width: 200px;
    }
}
