.dialog {

    border: none;

    &__submit {

        float: left;
        margin-top: 1.2rem;
    }

    &__delete {

        position: absolute;
        bottom: 3rem;
        right: 1rem;

        max-width: unset;
        width: 250px;

        .delete-formset-entry & {

            bottom: unset;
            top: 2rem;
            right: 2rem;
            z-index: 1;
        }
    }

    &__register {

        position: absolute;
        bottom: 100%;
        left: -.5rem;
        width: 300px;
    }

    &__cancel {

        position: absolute;
        bottom: 3rem;
        left: -1.3rem;
        width: 300px;
    }

    &.green {

        background-color: $drop-green;
    }

    &.orange {

        background-color: $drop-orange;
    }

    &.red {

        background-color: $drop-red;
    }
}
