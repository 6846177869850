.edit-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    text-align: right;
    height: 40px;
    background-color: rgba(0, 0, 0, .4);
    color: white;
}
