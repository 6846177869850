/* Base */
$bg-grey: #3c3c3d;
$error-color: #be2d2e;

/* Typo */
$text-base: #000;
$text-bar: #fff;
$text-light-grey: #757575;
$text-dark-grey: #3b3b3b;
$link-blue: #406fdb;
$placeholder-grey: #adadad;

/* Sidebar */
$bar-dark-blue: #1e2748;
$bar-light-blue: #3e4764;

/* Button */
$btn-blue: #406fdb;
$btn-border-grey: #757575;
$btn-inactive: #e6e6e6;
$btn-red: #cd4827;

/* Backdrop colors */
$drop-grey: #f1f1f1;
$drop-green: #e8f9e7;
$drop-red: #fae4e3;
$drop-orange: #fce9db;
$drop-blue: #e2efff;

/* Progress bar colors */
$progress-grey: #cdcdcd;
$progress-red: #cd4827;
$progress-orange: #f1996f;
$progress-light-green: #aed6aa;
$progress-dark-green: #558e4b;

/* Input */
$border-light-grey: #cdcdcd;

/* Bootstap Overrides */
$primary: #406fdb;
$secondary: #757575;
$danger: #cd4827;
$enable-negative-margins: true;
