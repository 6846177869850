.page {

    margin-left: 18rem;
    overflow-y: visible;
    z-index: 1;

    &__content {
        overflow-y: visible;
    }

    @include media-breakpoint-down(lg) {
        margin-left: 0;
    }
}
