.header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    color: $text-bar;
    background-color: $bg-grey;

    * {
        margin: 0;
    }

    &__welcome-msg {
        margin-left: 19rem;
    }

    &__menu-icon {
        height: 31px;
        width: 44px;
        cursor: pointer;
    }

    &__logout {
        text-decoration: none;
        color: $text-bar;

        &:hover {
            color: $text-bar;
        }

        &--icon {
            margin-right: 5px;
            height: 18px;
            width: 18px;
        }
    }

    @include media-breakpoint-down(lg) {
        left: 0;
    }
}
