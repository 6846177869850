button {

    border: none;
    background: none;
}

.delete-entry {

    position: absolute;
    top: var(--pos-top, 0);
    right: -2rem;
    width: fit-content;
}

button[type='submit'] {
    position: relative;
    z-index: 15;
}
