.table {

    > :not(:first-child) {

        border-top: 2px solid $btn-border-grey;
    }

    .dataTable-sorter {

        &.active {

            .icon {

                transform: rotateZ(180deg);
            }
        }
    }

    &__colored-value {

        &.green {

            color: #558e4b;
        }

        &.orange {

            color: #e26139;
        }

        &.red {

            color: #be2d2e;
        }
    }

    &__info {

        small {

            color: $text-light-grey;
        }

        > * {

            cursor: pointer;
        }

        .desktop-table & {
            svg {
                min-height: 48px;
                min-width: 48px;
            }
        }
    }

    &__infobox {

        width: 100%;
        color: $text-dark-grey;
        background: $drop-blue;
        border-radius: 2px;
    }

    &__file-btn {

        :hover {

            color: #fff;
        }

        .desktop-table & {
            svg {
                min-height: 20px;
                min-width: 20px;
            }
        }
    }
}
