body {

    font-family: Montserrat, system-ui, sans-serif !important;
}

form {

    .form-label {

        font-weight: bold;
        color: $text-dark-grey;

        small {

            font-weight: normal;
            color: $text-light-grey;
        }
    }

    .label-optional {

        color: $border-light-grey;
    }
}

.text-light-grey {

    color: $text-light-grey !important;
}

.text-dark-grey {

    color: $text-dark-grey !important;
}

.colored-value {

    &.green {

        color: #558e4b;
    }

    &.orange {

        color: #e26139;
    }

    &.red {

        color: #be2d2e;
    }
}
