@keyframes fade-out {

    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fade-in {

    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fold-in {

    from {

        max-height: 2000px;
        opacity: 1;
    }

    to {

        max-height: 0;
        opacity: 0;
    }
}

@keyframes fold-out {

    from {

        max-height: 0;
        opacity: 0;
    }

    to {

        max-height: 2000px;
        opacity: 1;
    }
}
