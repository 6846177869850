.fade-in {

    animation: fade-in .5s ease-out 0s forwards;
}

.fade-out {

    animation: fade-out .5s ease-out 0s forwards;
}

.fold-in {

    animation: fold-in .5s ease-out 0s forwards;
}

.fold-out {

    animation: fold-out 1s ease-out 0s forwards;
}
