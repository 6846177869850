.training {

    &__facts {

        &--label {
            color: $text-light-grey;
        }
    }

    &__left {

        padding-right: 5rem;
    }

    &__right {

        padding: 0 10rem 0 5rem;
    }

    @include media-breakpoint-down(lg) {

        &__left {

            padding: 0;
        }

        &__right {

            padding: 0;
        }
    }
}
