#form-modal,
#training-modal {

    position: fixed !important;
    top: 40px;
    left: 18rem;
    width: calc(100vw - 18rem);
    background-color: #fff;

    .modal-dialog {

        height: 100% !important;
        width: 100% !important;
        max-width: none !important;
        margin: 0 !important;
    }

    .modal-content {

        border: none !important;
        height: 100% !important;
        width: 100% !important;
    }

    .modal-header {

        border: none !important;
    }

    form {

        margin-bottom: 5rem;
    }

    @include media-breakpoint-down(lg) {
        width: 100vw;
        top: 47px;
        left: 0;
    }
}

#wizard {

    z-index: 1700;
    backdrop-filter: blur(5px);

    .modal-dialog {

        .modal-content {
            height: 90vh !important;
        }

        .modal-header {

            border-bottom: none;
        }
    }

    form {

        width: 100%;
        margin: 2rem 0;

        .form-content {

            width: 50%;

            &__3 {

                width: 75%;
            }

            @include media-breakpoint-down(xl) {

                width: 100%;
            }
        }
    }
}

#overview,
#contacts {

    z-index: 1700;

    button {

        &:hover {

            a {

                color: #fff;
            }
        }
    }

    a &:not(.contacts) {
        &:hover {

            color: #fff;
        }
    }
}
